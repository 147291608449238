<template>
    <div class="inner-view application-inner-view" v-if="vacancy">
        <button class="btn icon-btn go-back" @click="back">
            <svg xmlns="http://www.w3.org/2000/svg" width="19.829" height="21.451" viewBox="0 0 19.829 21.451">
                <g>
                    <g>
                        <path d="M6.118 1.032L2.8 4.349h8.477a8.6 8.6 0 0 1 8.552 8.551 8.653 8.653 0 0 1-8.551 8.551H1.917v-1.475h9.362a7.175 7.175 0 0 0 7.076-7.076 7.126 7.126 0 0 0-7.077-7.077H2.8L6.118 9.14l-1.032 1.032L0 5.086 5.086 0z" transform="translate(164) translate(-164)"/>
                    </g>
                </g>
            </svg>
        </button>
        <div class="container-fluid view view-item vacancy">
            <div class="content-view borderless">
                <div class="header">
                    <div class="header-image">
                        <img :src="vacancy.image" :alt="vacancy.title">
                    </div>
                    <div class="header-content">
                        <div class="title">
                            <h1>{{ vacancy.title }}</h1>
                        </div>
                        <ul class="item-list">
                            <li v-if="vacancy.location_description">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12.164" height="16.798" viewBox="0 0 12.164 16.798">
                                    <g>
                                        <path fill-rule="evenodd" d="M17.082 3A6.085 6.085 0 0 0 11 9.082a9.434 9.434 0 0 0 1.253 3.795 52.708 52.708 0 0 0 4.371 6.7.579.579 0 0 0 .916 0 52.708 52.708 0 0 0 4.371-6.7 9.434 9.434 0 0 0 1.253-3.795A6.085 6.085 0 0 0 17.082 3zm0 3.475a2.607 2.607 0 1 0 2.607 2.607 2.608 2.608 0 0 0-2.607-2.607z" transform="translate(-11 -3)"/>
                                    </g>
                                </svg>
                                <span>{{ vacancy.location_description }}</span>
                            </li>
                            <li v-if="vacancy.salaries?.length">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                                    <g>
                                        <g>
                                            <path d="M10.5 2a8.5 8.5 0 1 0 8.5 8.5A8.5 8.5 0 0 0 10.5 2zm2.55 11.05h-5.1a.85.85 0 0 1 0-1.7h5.1a.85.85 0 1 1 0 1.7zm0-3.4h-5.1a.85.85 0 1 1 0-1.7h5.1a.85.85 0 1 1 0 1.7z" transform="translate(-2 -2) translate(2 2) translate(-2 -2)"/>
                                        </g>
                                    </g>
                                </svg>
                                <span v-for="salary in vacancy.salaries" :key="salary">{{ salary.amount_from }} {{ salary.currency }}</span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.705" height="13.199" viewBox="0 0 21.705 13.199">
                                    <g>
                                        <g>
                                            <path d="M25.134 27.331C22.445 23.3 18.559 21 14.452 21S6.46 23.3 3.771 27.331L3.6 27.6l.171.269C6.46 31.9 10.346 34.2 14.452 34.2s7.993-2.3 10.681-6.331l.167-.269zM14.452 31.51a3.911 3.911 0 1 1 3.911-3.91 3.922 3.922 0 0 1-3.911 3.91z" transform="translate(-3.6 -21) translate(3.6 21) translate(-3.6 -21)"/>
                                        </g>
                                    </g>
                                </svg>
                                <span>views</span>
                            </li>
                            <li v-if="vacancy.is_public">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.705" height="13.199" viewBox="0 0 21.705 13.199">
                                    <g>
                                        <g>
                                            <path d="M25.134 27.331C22.445 23.3 18.559 21 14.452 21S6.46 23.3 3.771 27.331L3.6 27.6l.171.269C6.46 31.9 10.346 34.2 14.452 34.2s7.993-2.3 10.681-6.331l.167-.269zM14.452 31.51a3.911 3.911 0 1 1 3.911-3.91 3.922 3.922 0 0 1-3.911 3.91z" transform="translate(-3.6 -21) translate(3.6 21) translate(-3.6 -21)"/>
                                        </g>
                                    </g>
                                </svg>
                                <span>{{ $t('Public vacancy') }}</span>
                            </li>
                            <li v-if="vacancy.is_premium">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                                    <g>
                                        <g>
                                            <path d="M10.5 2a8.5 8.5 0 1 0 8.5 8.5A8.5 8.5 0 0 0 10.5 2zm2.55 11.05h-5.1a.85.85 0 0 1 0-1.7h5.1a.85.85 0 1 1 0 1.7zm0-3.4h-5.1a.85.85 0 1 1 0-1.7h5.1a.85.85 0 1 1 0 1.7z" transform="translate(-2 -2) translate(2 2) translate(-2 -2)"/>
                                        </g>
                                    </g>
                                </svg>
                                <span>{{ $t('Premium vacancy') }}</span>
                            </li>
                            <li v-if="employment_dates">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17.57" height="17.218" viewBox="0 0 17.57 17.218">
                                    <g>
                                        <path d="M17.2 5.064h-1.828v-.906a.762.762 0 0 0-.758-.758.746.746 0 0 0-.74.758v.906H8.7v-.906a.762.762 0 0 0-.763-.758.75.75 0 0 0-.758.758v.906H5.367A2.869 2.869 0 0 0 2.5 7.931v9.82a2.869 2.869 0 0 0 2.867 2.867H17.2a2.869 2.869 0 0 0 2.867-2.867v-9.82A2.881 2.881 0 0 0 17.2 5.064zM5.367 6.563H7.2v.906a.762.762 0 0 0 .758.758.75.75 0 0 0 .758-.758v-.906h5.178v.906a.762.762 0 0 0 .758.758.75.75 0 0 0 .758-.758v-.906h1.79a1.365 1.365 0 0 1 1.369 1.369v2.218H4V7.931a1.365 1.365 0 0 1 1.367-1.368z" transform="translate(-13.5 -196.4) translate(11 193)"/>
                                    </g>
                                </svg>
                                <span v-html="employment_dates"></span>
                            </li>
                            <li v-if="hours_per_week">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                    <g>
                                        <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm3.553 12.894l-4-2A1 1 0 0 1 11 12V7a1 1 0 0 1 2 0v4.382l3.447 1.724a1 1 0 1 1-.895 1.789z" transform="translate(-2 -2)"/>
                                    </g>
                                </svg>
                                <span v-html="hours_per_week"></span>
                            </li>
                        </ul>

                        <div class="header-buttons">
                            <button type="button" class="btn btn-secondary" disabled="disabled">{{ $t('Status') }}</button>
                        </div>
                    </div>
                </div>
                <div class="item-content">
                    <template v-if="application.candidate_message">
                        
                        <div class="item-content-section">
                            <h2>{{ $t("Message") }}</h2>
                            <p>{{ application.candidate_message }}</p>
                        </div>

                        <hr />

                    </template>
                    
                    <div class="item-footer-buttons d-flex align-items-center justify-content-between">
                        <div class="left-buttons">
                            <a class="btn btn-secondary" :href="`/vacancies/${slugify(vacancy.title)}-${vacancy.id}`" target="_BLANK">{{ $t('View') }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import slugify from "@/js/slugify";
import { vacancyService } from "@/services/vacancyService";
import VacancyView from '@/views/vacancies/ViewVacancy';

export default {
    props: ['application'],
    data() {
        return {
            slugify: slugify,
            vacancy: null
        }
    },
    components: {
        VacancyView
    },
    methods: {
        getVacancy() {
            vacancyService.getVacancy(this.application.vacancy_id).then(response => this.vacancy = response.data.data)
        },
        back() {
            this.$emit('switchActiveComponent', 'applications');
        }
    },
    mounted() {
        this.getVacancy();
    },
    computed: {
        employment_dates() {
            if (this.vacancy.start_date && this.vacancy.end_date) {
                return `<span><b>${this.$t('Start date')}:</b> ${this.vacancy.start_date}</span> <span><b>${this.$t('End date')}:</b> ${this.vacancy.end_date}</span>`;
            } else if (this.vacancy.start_date) {
                return `<b>${this.$t('Start date')}:</b> ${this.vacancy.start_date}`;
            } else if (this.vacancy.end_date) {
                return `<b>${this.$t('End date')}:</b> ${this.vacancy.end_date}`;
            } else {
                return null;
            }
        },
        hours_per_week() {
            if (this.vacancy.hours_per_week_from && this.vacancy.hours_per_week_to) {
                return `${this.vacancy.hours_per_week_from} - ${this.vacancy.hours_per_week_to} ${this.$t('Hours per week')}`;
            } else if (this.vacancy.hours_per_week_from) {
                return `${this.vacancy.hours_per_week_from} ${this.$t('Hours per week')}`;
            } else if (this.vacancy.hours_per_week_to) {
                return `${this.vacancy.hours_per_week_to} ${this.$t('Hours per week')}`;
            } else {
                return null;
            }
        }
    }
}
</script>